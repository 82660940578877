import React from 'react';

import Layout from '../../components/layout';
import Exhibitions from '../../components/exhibitions';
import SEO from '../../components/seo';

const IndexPage = () => (
  <Layout locale="pt-BR">
    <SEO
      lang="pt-BR"
      title="Exposições"
      description="Lista de exposições da artista Katharina Welper."
    />
    <div className="page">
      <div className="about-section">
        <Exhibitions locale="pt-BR" />
      </div>
    </div>
  </Layout>
);

export default IndexPage;
